












































import { Component, Vue } from "vue-property-decorator";
import { JobDto } from "@/api/appService";
import api from "@/api";
@Component({
  name: "JobDetail",
  components: {},
})
export default class JobDetail extends Vue {
  detail: JobDto = {};

  loading = true;

  created() {
    if (this.$route.params.id) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    this.loading = true;
    api.job.get({ id: Number(this.$route.params.id) }).then((res) => {
      this.detail = { ...res };
      this.loading = false;
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
